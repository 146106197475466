<template>
    <nav class="nav-bar-pill">
        <ul class="nav-links">
            <li v-for="(navigation, index) in webNavigationLinks" :key="index" class="nav-link">
                <router-link
                    v-intro-autostart="showIntro"
                    v-intro-autostart.config="{ showStepNumbers: false, prevLabel: 'Previous', nextLabel: 'Next', hidePrev: true, hideNext: true }"
                    v-intro-autostart:on.complete="onIntroCompleted"
                    v-intro-autostart:on.exit="onExit"
                    v-intro="navigation.introText"
                    v-intro-position="'bottom-left-aligned'"
                    v-intro-tooltip-class="`desktop step-${index + 1}`"
                    :to="{ name: navigation.routeName, params: navigation.routeParams }"
                    @click.native="toggleDraftsList(navigation.routeName)"
                >
                    <span>{{ navigation.name }}</span>
                    <template v-if="navigation.name === 'Inbox' && userNotification !== 0">
                        <span class="notification-badge badge badge-danger">{{ userNotification }}</span>
                    </template>
                </router-link>
            </li>
            <div class="user-options">
                <v-popover>
                    <div class="tooltip-target b3 user-image">
                        <img :src="userData.photo? userData.photo.url : require('@assets/img/icons/avatar.png')">
                    </div>

                    <template slot="popover">
                        <div class="popover-options">
                            <router-link :to="{ name: 'web-app-profile', params: { displayName: userData.displayname } }" class="popover-option">
                                <span>Profile</span>
                                <img src="@assets/img/icons/avatar.svg" alt="Profile" style="margin-right: 5px;">
                            </router-link>
                            <router-link :to="{ name: 'web-app-profile-settings', params: { displayName: userData.displayname } }" class="popover-option">
                                <span>Settings</span>
                                <img src="@assets/img/icons/settings.svg" alt="Settings">
                            </router-link>
                            <a href="javascript:void(0)" class="popover-option" @click="logout">
                                <span>Sign out</span>
                                <img src="@assets/img/icons/sign-out.svg" alt="Logout">
                            </a>
                        </div>
                    </template>
                </v-popover>
            </div>
        </ul>
    </nav>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    name: "NavBarPill",
    props: {
        showIntro: {
            type: Boolean,
            default: false
        },
        navigationLinks: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            webNavigationLinks: []
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        ...mapState({
            userData: state => state.User.data,
            showDraftsList: state => state.Application.showDraftsList
        }),
        userNotification() {
            // eslint-disable-next-line camelcase
            const { new_notification } = this.userData;

            // eslint-disable-next-line camelcase
            return new_notification ? new_notification : 0;
        }
    },
    created() {
        this.setWebNavigationLinks();
    },
    mounted() {
        this.notificationPusherChannel();
    },
    beforeDestroy() {
        this.$pusher.unsubscribe(`user-profile-${this.userData.id}`);
    },
    methods: {
        setWebNavigationLinks() {
            const webNavigationLinks = [...this.navigationLinks];
            const profileNavigationLinkIndex = webNavigationLinks.findIndex(navigationLink => navigationLink.routeName == "web-app-profile");
            webNavigationLinks.splice(profileNavigationLinkIndex, 1);
            this.webNavigationLinks = webNavigationLinks;
        },
        onIntroCompleted() {
            this.$emit("on-intro-completed");
        },
        notificationPusherChannel() {
            this.$pusher.subscribe(`user-profile-${this.userData.id}`).bind("notificationsCounter", async(data) => {
                await this.$store.dispatch("User/resetNotification", data.total);
            });
        },
        onExit() {
            this.$amplitude.logEvent("skip-onboarding");
            this.$emit("on-exit");
        },
        async logout() {
            this.$amplitude.logEvent("Logout");
            this.$emit("is-loading", true);

            await this.$router.push({ name: "landing" });
            await this.$store.dispatch("Application/resetGlobalData");
            
            window.location.reload();
        },
        toggleDraftsList(routeName) {
            // If the user just clicked the "Create" button in the nav pill and the drafts list is open
            // bussiness logic dictates we need to let the user create a memo from scratch
            if (routeName === "web-app-create") {
                this.$emit("reload-composer")
            }
            this.$store.dispatch("Application/toggleDraftsList", false);
        }
    }
}
</script>

<style lang="scss" scoped>
.nav-bar-pill {
    background-color: #2C2C2E;
    padding: 10px 12px;
    position: fixed;
    top: 15px;
    right: 15px;
    border-radius: 40px;
    z-index: 10;

    .nav-links {
        padding-left: 0;
        margin-bottom: 0;
        display: flex;

        .nav-link {
            margin-left: 10px;
            padding: 0;
            position: relative;

            a {
                font-weight: bold;
                font-size: 16px;
                display: block;
                border-radius: 40px;
                padding: 0 12px;
                color: white;
                text-decoration: none;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                &.router-link-active {
                    background-color: $primary-color;
                }
            }

            &:first-child {
                margin-left: 0;
            }

            .notification-badge {
                background: #05A88F;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                border-radius: 100%;

                position: absolute;
                top: -3px;
                right: -3px;
            }
        }

        .user-options {
            display: flex;
            align-items: center;

            .v-popover {
                display: flex;
            }

            .user-image {
                width: 30px;
                height: 30px;
                margin-left: 15px;
                background-color: #1C1C1E;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                cursor: pointer;
                color: white;
                text-decoration: none;
                font-weight: 400;
                font-size: 16px;
                overflow: hidden;
                z-index: 99;

                img {
                    width: 100%;
                }
            }

            .tooltip-target {
                cursor: pointer;
            }
        }
    }

    &.introjs-fixParent {
        z-index: 9999999 !important;
    }
}
</style>
